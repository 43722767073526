import React from "react";
import { Switch, Route } from "react-router-dom";

import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { CssBaseline } from "@material-ui/core";

import SignIn from "../Pages/General/Authentication/SignIn";

import BusinessSignIn from "../Pages/Business/Authentication/SignIn";
import ForgotPassword from "../Pages/General/Authentication/ForgotPassword";
import BusinessForgotPassword from "../Pages/Business/Authentication/ForgotPassword";
import Home from "../Pages/General/Home";
import BusinessHome from "../Pages/Business/Home";
import Profile from "../Pages/General/Profile";
import EditProfile from "../Pages/General/Profile/EditProfile";
import Insights from "../Pages/General/Insights";
import Wallet from "../Pages/General/Wallet";
import Saved from "../Pages/General/Saved";
import League from "../Pages/General/League";
import Invite from "../Pages/General/Invite";
import Recruiter from "../Pages/General/Recruiter";
import Settings from "../Pages/General/Settings";
import Subscriptions from "../Pages/General/Subscriptions";
import HireRecruiter from "../Pages/General/Recruiter/Hire";
import RecruiterProfile from "../Pages/General/Recruiter/Profile";
import ActivityTracker from "../Pages/General/Recruiter/Profile/ActivityTracker";
import Performance from "../Pages/General/Recruiter/Profile/Performance";
import Requests from "../Pages/General/Recruiter/Requests";
import RequestsSent from "../Pages/General/Recruiter/Requests-sent";
import RecruiterInsights from "../Pages/General/Recruiter/Insights";
import ShortLists from "../Pages/General/Recruiter/Shortlist";
import ProtectedRoute from "./ProtectedRoute";
import PublicRoute from "./PublicRoute";
import { bool } from "prop-types";
import PerformanceManagement from "../Pages/Business/PerformanceManagement";
import Referrals from "../Pages/Business/Referrals";
import Admin from "../Pages/Business/Admin";
import { ternaryResolver } from "../utils/helpers";
import SignUp from "../Pages/General/Authentication/SignUp";
import BusinessSignUp from "../Pages/Business/Authentication/SignUp";
import NotFound from "../Pages/General/NotFound";
import BecomeRecruiter from "../Pages/General/Recruiter/BecomeRecruiter";
import OtherUser from "../Pages/General/Profile/OtherUser";
import MyRecruiterProfile from "../Pages/General/MyRecruiterProfile";
import EditRecruiterProfile from "../Pages/General/MyRecruiterProfile/EditRecruiterProfile";
import MyJobs from "../Pages/General/MyJobs";
import Confirmation from "../Pages/General/Confirmation";
import JobListPublicDetails from "../Pages/General/JobListPublic/details";
import Organization from "../Pages/General/organization";

const theme = createMuiTheme({
  typography: {
    button: {
      textTransform: "none",
    },
    fontFamily: ['"Poppins"', "sans-serif"].join(","),
  },
  palette: {
    type: "light",
    primary: {
      main: "#3A6ED4",
    },
    secondary: {
      main: "#fff",
    },
    warning: {
      main: "#000",
    },
  },
});

const Router = ({ business }) => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />

      <Switch>
        <PublicRoute
          exact
          path={["/", "/signin"]}
          component={ternaryResolver(business, BusinessSignIn, SignIn)}
        />

        <PublicRoute
          exact
          path={["/signup", "/signup/:inviteCode", "/activate"]}
          component={ternaryResolver(business, BusinessSignUp, SignUp)}
        />

        <PublicRoute
          exact
          path={["/job-lists-details/:jobId/apply/:refLink"]}
          component={JobListPublicDetails}
        />

        {/* <PublicRoute
          exact
          path={[
            "/",
            "/job-lists-details",
            "/job-lists-details/:jobId",
            "/job-lists-details/:jobId/apply",
            "/job-lists-details/:jobId/apply/:refLink"
          ]}
          component={JobListPublicDetails}
        /> */}

        <PublicRoute
          exact
          path={["/forgotPassword", "/forgotPassword/reset"]}
          component={ternaryResolver(
            business,
            BusinessForgotPassword,
            ForgotPassword
          )}
        />

        <ProtectedRoute
          exact
          path={[
            "/",
            "/home",
            "/home/:jobId",
            "/home/:jobId/recommended",
            "/home/:jobId/apply",
            "/home/:jobId/apply/:refLink",
            "/home/:jobId/apply/:refLink/coy/:company_id", //Referral: Jobs in "WB Biz Referrals" tabs - pushed to you from business
            "/home/:jobId/apply/:recruiterId/:refLink",
            "/home/:jobId/apply/:recruiterId/:refLink/biz", // Get from business_jobs table instead of jobs. Same as above
            "/home/:jobId/refer",
          ]}
          component={ternaryResolver(business, BusinessHome, Home)}
        />

        <ProtectedRoute
          exact
          path={[
            "/profile/edit",
            "/profile/edit/basic",
            "/profile/edit/about",
            "/profile/edit/education",
            "/profile/edit/education/:educationId",
            "/profile/edit/skills",
            "/profile/edit/resume",
            "/profile/edit/experience",
          ]}
          component={EditProfile}
        />

        <ProtectedRoute exact path="/profile" component={Profile} />

        <ProtectedRoute exact path="/profile/:id" component={OtherUser} />

        <ProtectedRoute
          exact
          path={[
            "/settings",
            "/settings/security",
            "/settings/account",
            "/settings/help",
            "/settings/about",
            "/settings/notifications",
            "/settings/bank",
          ]}
          component={Settings}
        />

        <ProtectedRoute
          exact
          path={[
            "/subscriptions",
            "/subscriptions/post-job",
            "/subscriptions/recruiters",
            "/subscriptions/active",
          ]}
          component={Subscriptions}
        />

        <ProtectedRoute exact path={["/recruiter"]} component={Recruiter} />

        <ProtectedRoute
          exact
          path={["/recruiter/hire", "/recruiter/hire/:jobId"]}
          component={HireRecruiter}
        />

        <ProtectedRoute
          exact
          path="/recruiter/become-recruiter"
          component={BecomeRecruiter}
        />
        <ProtectedRoute
          exact
          path="/organizational-chart"
          component={Organization}
        />

        <ProtectedRoute
          exact
          path="/recruiter/hire/profile/:id/:jobId"
          component={RecruiterProfile}
        />
        {/* <ProtectedRoute
        exact
        path="/recruiter/profile/:id/:jobId"
        component={RecruiterProfile}
      /> */}
        <ProtectedRoute
          exact
          path="/recruiter/profile"
          component={MyRecruiterProfile}
        />

        <ProtectedRoute
          exact
          path="/recruiter/profile/edit"
          component={EditRecruiterProfile}
        />

        <ProtectedRoute
          exact
          path={["/recruiter/activity/:id"]}
          component={ActivityTracker}
        />

        <ProtectedRoute
          exact
          path="/recruiter/performance/:id"
          component={Performance}
        />

        <ProtectedRoute exact path="/recruiter/sent" component={RequestsSent} />

        <ProtectedRoute exact path="/recruiter/requests" component={Requests} />
        <ProtectedRoute
          path="/recruiter/requests/:jobId"
          component={Requests}
        />
        <ProtectedRoute
          exact
          path="/recruiter/insights"
          component={RecruiterInsights}
        />

        <ProtectedRoute
          exact
          path={[
            "/recruiter/insights/applicants",
            "/recruiter/insights/applicants/:id",
          ]}
          component={RecruiterInsights}
        />

        <ProtectedRoute
          exact
          path={[
            "/recruiter/shortlist",
            "/recruiter/shortlist/applicants",
            "/recruiter/shortlist/applicants/:id",
          ]}
          component={ShortLists}
        />

        <ProtectedRoute
          exact
          path={["/invite", "/invite/link", "/invite/invitees"]}
          component={Invite}
        />

        <ProtectedRoute
          exact
          path={["/league", "/league/points", "/league/list"]}
          component={League}
        />

        <ProtectedRoute
          exact
          path={["/saved", "/saved/:jobId"]}
          component={Saved}
        />
        <ProtectedRoute
          exact
          path={["/confirmation"]}
          component={Confirmation}
        />
        <ProtectedRoute
          exact
          path={["/confirmation"]}
          component={Confirmation}
        />

        <ProtectedRoute
          exact
          path={[
            "/wallet",
            "/wallet/withdraw",
            "/wallet/history",
            "/wallet/add",
          ]}
          component={Wallet}
        />

        <ProtectedRoute
          exact
          path={[
            "/insights",
            "/insights/jobs",
            "/insights/jobs/applicants/:id",
            "/insights/jobs/requests/:id",
            "/insights/jobs/applicants/:id/:stage",
            "/insights/referrals",
            "/insights/applications",
          ]}
          component={Insights}
        />

        <ProtectedRoute exact path={["/myjobs"]} component={MyJobs} />

        <ProtectedRoute
          exact
          path={["/referrals", "/referrals/insights", "/referrals/sent"]}
          component={Referrals}
        />

        <ProtectedRoute
          exact
          path={[
            "/admin",
            "/admin/insights",
            "/admin/jobs",
            "/admin/requests",
            "/admin/qualityofhire",
          ]}
          component={Admin}
        />
        <ProtectedRoute
          exact
          path={[
            "/performance",
            "/performance/addperformance",
            "/performance/adminapproval",
            "/performance/adminreview",
            "/performance/employeeapprasial",
            "/performance/reviewpeformance",
            "/performance/rewardperformance",
            "/performance/supervisorapproval",
            "/performance/supervisorreview",
            "/performance/supervisorreward",
          ]}
          component={PerformanceManagement}
        />

        <ProtectedRoute
          exact
          path={[
            "/admin/jobs/applicants/:id",
            "/admin/jobs/:jobId",
            "/admin/jobs/applicants/:id/:stage",
          ]}
          component={Admin}
        />

        <Route path="*" component={NotFound} />
      </Switch>
    </ThemeProvider>
  );
};

Router.propTypes = {
  business: bool.isRequired,
};

export default Router;
