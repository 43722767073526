import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { bool, func } from "prop-types";
import { IconButton } from "@material-ui/core";
import icons from "../../../assets/icons/icon-collection.svg";
import CustomButton from "../../../components/CustomButton";
import {
  createBusinessJobAction,
  getSubsidiaries,
} from "../../../redux/actions/businessActions";
import { getJobLevelsAction } from "../../../redux/actions/jobActions";
import { getIndustryAction } from "../../../redux/actions/industryActions";
import { getAllSkillsAction } from "../../../redux/actions/skillsAction";
import JobDetails from "./JobDetails";
import AddDocuments from "./JobDocuments";

const BusinessAddNewPost = ({ open, handleClose }) => {
  const dispatch = useDispatch();
  const [industryOptions, setIndustryOptions] = useState({});
  const [section, setSection] = useState("details");
  const [levelOptions, setLevelOptions] = useState({});
  const [skillOptions, setSkillOptions] = useState({});
  const [subsidiaryOptions, setSubsidiaryOptions] = useState({});
  const [linksObject, setLinksObject] = useState([]);
  const [jobCreated, setJobCreated] = useState(false);
  const [newJob, setNewJob] = useState({
    company_id: "0",
    subsidiary_id: "0",
    location: "",
    industry: "0",
    department: "",
    job_level: "0",
    role_title: "",
    about_role: "",
    job_type: "0",
    reports_to: "",
    applicant_benefit: "salary",
    skills_required: [],
    additional_files: [],
  });
  const [loading, setLoading] = useState(false);
  const { industry, skill, job, business } = useSelector((store) => store);
  const { allSkills } = skill;
  const { industries } = industry;
  const { jobLevels } = job;
  const {
    userData: { account_type, company },
  } = useSelector((store) => store.auth);

  const company_id = company ? company.id : "";
  const addDocLinkObject = (link) => {
    const links = [...linksObject];
    links.push(link);
    setLinksObject(links);
  };

  useEffect(() => {
    Promise.all([
      getIndustryAction(dispatch),
      getJobLevelsAction(dispatch),
      getAllSkillsAction(dispatch),
      getSubsidiaries(dispatch, company_id),
    ]);
  }, [dispatch]);

  useEffect(() => {
    const options = {};
    industries &&  industries.forEach(
      (industry) => (options[industry.title] = industry.title)
    );
    setIndustryOptions(options);
    if (account_type === "business") {
     // console.log(`industries === ${(company_id)}`);
      setNewJob({
        ...newJob,
        //industry: industries,  //&&  industries[company.industry]?.title || "",
      });
    }
  }, [industries]);

  useEffect(() => {
    const options = {};
    allSkills.forEach((skill) => (options[skill.title] = skill.title));
    // setSkillOptions(options);
    setSkillOptions(allSkills);
  }, [allSkills]);

  useEffect(() => {
    const options = {};
    jobLevels &&
      jobLevels.forEach((level) => (options[level.id] = level.title));
    setLevelOptions(options);
  }, [jobLevels]);

  useEffect(() => {
    const options = {};
    const subsidiaries = business ? business.subsidiaries : [];
    subsidiaries &&
      subsidiaries.forEach(
        (subsidiary) => (options[subsidiary.id] = subsidiary.subsidiary_name)
      );
    setSubsidiaryOptions(options);
  }, [business]);

  const handleChange = ({ target: { value, name } }) => {
    const changedObject = {};
    changedObject[name] = value;
    setNewJob({ ...newJob, ...changedObject });
  };

  const setSelectedSkills = (skills) => {
    setNewJob({ ...newJob, ...{ skills_required: skills } });
  };

  const addDocLink = (link) => {
    const links = [...newJob.additional_files];
    links.push(link);
    setNewJob({ ...newJob, ...{ additional_files: links } });
  };

  const handleSubmit = (e) => {
    const job_level = parseInt(newJob.job_level, 10);
    setLoading(true);

    e.preventDefault();
    // console.log("newJob", newJob);
    if (section === "documents") {
      setSection("details");
    }
    createBusinessJobAction(
      (action) => {
        setJobCreated(true);
        dispatch(action);
      },
      {
        ...newJob,
        ...{ job_level, company_id: parseInt(company_id, 10) },
      }
    );

    setLoading(false);
  };

  const departmentOptions = {
    none: "None",
    engineering: "Engineering",
    customer_service: "Customer Service",
    information_technology: "Information and Technology",
    human_resources: "Human Resources",
    marketing: "Marketing",
    operations: "Operations",
    finance: "Finance",
    public_relations: "Public Relations",
    general_management: "General Management",
  };

  return (
    <div>
     
    </div>
  );
};

BusinessAddNewPost.propTypes = {
  open: bool.isRequired,
  handleClose: func.isRequired,
};

export default BusinessAddNewPost;
