import React, { forwardRef, useState } from "react";
import { string, bool, func } from "prop-types";
import "./index.scss";
import { Tooltip } from "@material-ui/core";

const CustomInput = forwardRef(
  (
    {
      tooltipText,
      name,
      type,
      value,
      label,
      id,
      isRequired,
      onChange,
      onLoad,
      placeholder,
      isDisabled,
      additionalClass,
      inputStyle,
      icon,
      onBlur,
      onFocus,
    },
    ref
  ) => {
    const [passwordShown, setPasswordShown] = useState(false);

    const togglePasswordVisiblity = () => {
      setPasswordShown(passwordShown ? false : true);
    };
    return (
      <div
        className={`${
          inputStyle === "TextField" ? "custom-text-field" : "custom-input"
        } mt-3 w-100`}
      >
        <label className="d-block" htmlFor={id}>
          {label}
          {isRequired && <span className="co-lightred"> * </span>}
        </label>
        <div className="input-wrapper">
          <Tooltip title={tooltipText}>
            <input
              ref={ref}
              className={`w-100 ${additionalClass}`}
              name={name}
              type={
                type === "password"
                  ? passwordShown
                    ? "text"
                    : "password"
                  : type
              }
              value={value}
              id={id}
              required={isRequired}
              disabled={isDisabled}
              placeholder={placeholder}
              onChange={onChange}
              onLoad={onLoad}

              onBlur={onBlur ? onBlur : () => {}}
              onFocus={onFocus ? onFocus : () => {}}
            />
          </Tooltip>
          {icon && (
            <img
              className="password-revealer-icon"
              src={icon}
              width="17"
              onClick={togglePasswordVisiblity}
            />
          )}
        </div>
      </div>
    );
  }
);

CustomInput.propTypes = {
  value: string.isRequired,
  name: string,
  placeholder: string,
  additionalClass: string,
  id: string.isRequired,
  isRequired: bool,
  label: string,
  onChange: func,
  isDisabled: bool,
  type: string,
  tooltipText: string,
  inputStyle: string,
  icon: string,
  onBlur: func,
  onFocus: func,
};

CustomInput.defaultProps = {
  isDisabled: false,
  isRequired: false,
  onChange: () => {},
  onBlur: () => {},
  onFocus: () => {},
  additionalClass: "",
  type: "",
  label: "",
  placeholder: "",
  name: "",
  tooltipText: "",
  icon: "",
};

CustomInput.displayName = "CustomInput";

export default CustomInput;
